import * as React from "react"
import PropTypes from "prop-types"
import {Link} from "gatsby"
import {Location} from '@reach/router'
import {StaticImage} from "gatsby-plugin-image";

const Header = ({siteTitle}) => {

    return (
        <Location>
            {({ location }) => {

                return (
                    <header className="bg-white">
                        <nav className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8" aria-label="Top">
                            <div
                                className="w-full py-6 flex items-center justify-between border-b border-green-500 lg:border-none">
                                <div className="flex items-center">
                                    <Link to={"/"}>
                                        <span className="sr-only">Jan Skurovec</span>
                                        <StaticImage width={50} className="h-10 w-auto rounded-lg" src={'../images/skurovec.png'} alt={'site logo'} />
                                    </Link>
                                    <div className="hidden ml-10 space-x-8 lg:block">

                                        {location.pathname.startsWith('/cz') ? <>
                                            <Link
                                                className="text-base font-medium text-gray-900 hover:text-green-50"
                                                activeClassName="text-green-500"
                                                to={"/cz"}
                                            >
                                                Vývoj webových aplikací
                                            </Link>

                                            <Link
                                                className="text-base font-medium text-gray-900 hover:text-green-50"
                                                activeClassName="text-green-500"
                                                to={"/cz/3d-tisk"}
                                            >
                                                Reference
                                            </Link>

                                            {/*<Link*/}
                                            {/*    className="text-base font-medium text-gray-900 hover:text-green-50"*/}
                                            {/*    activeClassName="text-green-500"*/}
                                            {/*    to={"/cz/3d-tisk"}*/}
                                            {/*>*/}
                                            {/*    3D Tisk*/}
                                            {/*</Link>*/}

                                            {/*<Link*/}
                                            {/*    className="text-base font-medium text-gray-900 hover:text-green-50"*/}
                                            {/*    activeClassName="text-green-500"*/}
                                            {/*    to={"/cz/rendering"}*/}
                                            {/*>*/}
                                            {/*    Rendering*/}
                                            {/*</Link>*/}
                                        </> : <>
                                            <Link
                                                className="text-base font-medium text-gray-900 hover:text-green-50"
                                                activeClassName="text-green-500"
                                                to={"/"}
                                            >
                                                Web development
                                            </Link>

                                            <Link
                                                className="text-base font-medium text-gray-900 hover:text-green-50"
                                                activeClassName="text-green-500"
                                                to={"/references"}
                                            >
                                                References
                                            </Link>

                                            {/*<Link*/}
                                            {/*    className="text-base font-medium text-gray-900 hover:text-green-50"*/}
                                            {/*    activeClassName="text-green-500"*/}
                                            {/*    to={"/3d-printing"}*/}
                                            {/*>*/}
                                            {/*    3D Printing*/}
                                            {/*</Link>*/}

                                            {/*<Link*/}
                                            {/*    className="text-base font-medium text-gray-900 hover:text-green-50"*/}
                                            {/*    activeClassName="text-green-500"*/}
                                            {/*    to={"/rendering"}*/}
                                            {/*>*/}
                                            {/*    Rendering*/}
                                            {/*</Link>*/}
                                        </>}


                                    </div>
                                </div>
                                <div className="ml-10 space-x-4">
                                    {/*<Link*/}
                                    {/*    to={location.pathname.startsWith('/cz') ? "/" : "/cz"}*/}
                                    {/*    className="inline-block bg-green-500 py-2 px-4 border border-transparent rounded-md text-base font-medium text-white hover:bg-opacity-75"*/}
                                    {/*>*/}
                                    {/*    {location.pathname.startsWith('/cz') ? "English" : "Česky"}*/}
                                    {/*</Link>*/}
                                </div>
                            </div>
                            <div className="py-4 flex flex-wrap justify-center space-x-6 lg:hidden">
                                {location.pathname.startsWith('/cz') ? <>
                                    <Link
                                        className="text-base font-medium text-gray-900 hover:text-green-50"
                                        activeClassName="text-green-500"
                                        to={"/cz"}
                                    >
                                        Vývoj webových aplikací
                                    </Link>

                                    <Link
                                        className="text-base font-medium text-gray-900 hover:text-green-50"
                                        activeClassName="text-green-500"
                                        to={"/cz/3d-tisk"}
                                    >
                                        Reference
                                    </Link>

                                    {/*<Link*/}
                                    {/*    className="text-base font-medium text-gray-900 hover:text-green-50"*/}
                                    {/*    activeClassName="text-green-500"*/}
                                    {/*    to={"/cz/3d-tisk"}*/}
                                    {/*>*/}
                                    {/*    3D Tisk*/}
                                    {/*</Link>*/}

                                    {/*<Link*/}
                                    {/*    className="text-base font-medium text-gray-900 hover:text-green-50"*/}
                                    {/*    activeClassName="text-green-500"*/}
                                    {/*    to={"/cz/rendering"}*/}
                                    {/*>*/}
                                    {/*    Rendering*/}
                                    {/*</Link>*/}
                                </> : <>
                                    <Link
                                        className="text-base font-medium text-gray-900 hover:text-green-50"
                                        activeClassName="text-green-500"
                                        to={"/"}
                                    >
                                        Web development
                                    </Link>

                                    <Link
                                        className="text-base font-medium text-gray-900 hover:text-green-50"
                                        activeClassName="text-green-500"
                                        to={"/references"}
                                    >
                                        References
                                    </Link>

                                    {/*<Link*/}
                                    {/*    className="text-base font-medium text-gray-900 hover:text-green-50"*/}
                                    {/*    activeClassName="text-green-500"*/}
                                    {/*    to={"/3d-printing"}*/}
                                    {/*>*/}
                                    {/*    3D Printing*/}
                                    {/*</Link>*/}

                                    {/*<Link*/}
                                    {/*    className="text-base font-medium text-gray-900 hover:text-green-50"*/}
                                    {/*    activeClassName="text-green-500"*/}
                                    {/*    to={"/rendering"}*/}
                                    {/*>*/}
                                    {/*    Rendering*/}
                                    {/*</Link>*/}
                                </>}
                            </div>
                        </nav>
                    </header>
                )
            }}
        </Location>
    )
}


Header.propTypes = {
    siteTitle: PropTypes.string,
}

Header.defaultProps = {
    siteTitle: ``,
}

export default Header
